import React from "react"
import styled, { withTheme } from "styled-components"

const List = ({ theme, children }: any) => {
  const Ul = styled.ul`
    font-size: 18px;
    list-style: none;
    margin: 0;
    padding-inline-start: 1.5rem;
    li {
      margin-bottom: 1rem;
      word-break: keep-all;
      &:before {
        content: "+";
        margin-left: -1.5rem;
        margin-right: 0.75rem;
        color: ${theme.colors.secondary};
      }
    }
  `

  return <Ul>{children}</Ul>
}

export default withTheme(List)
