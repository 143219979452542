import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo/seo"

import Contact from "../components/sections/contact"

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="CEO amp: contact"
        description="Fill out your details in the form to find out how we will amplify your story and elevate your company through thought leadership."
      />
      <Contact />
    </Layout>
  )
}

export default IndexPage
